@font-face {
  font-family: 'Montserrat BoldItalic';
  src: local('Montserrat BoldItalic'), url(./fonts/Montserrat-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat Medium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');}
  .button{
    margin: 20px;
  }
  .Footer{
    background-color: #1565C0;
    padding-top: 3%;
    padding-bottom: 1%;
  }
  .button1{
    background-image: url(./img/Button1.png) ;
    background-size: cover; 
  }
  .button2{
    background-image: url(./img/Button2.png) ;
    background-size: cover; 
  }
  .button3{
    background-image: url(./img/Button3.png) ;
    background-size: cover; 
  }
  .button4{
    background-image: url(./img/Button4.png) ;
    background-size: cover; 
  }
  .center{
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .css-3reny1-MuiGrid-root{
    background: repeating-linear-gradient(45deg, #1565C0, #1565C0 10px, #465298 10px, #465298 20px);
    background-color: #1565C0;
    align-items: center;
  }

  .UslugiBox{
    display: flex;
    width: 100%;
    height: 200px;
  }
  .innerBox{
    width: 100%;
    margin: auto;

  }
  .IconUslug{
    width: 6%;
    padding-top: -2%;

  }

  table {
    border-collapse: collapse;

    border: 1px solid grey;

 }
  td {
    border: 1px solid grey;
 }
  th {
    border: 1px solid grey;
 }
 .knopki{
    display: flex;
    justify-content: center;
 }
  .map{
    margin: 1%;
    /* width: 50%;
    height: 50%; */
  }
  .PhotoExamples{
    background-size: cover;
  }
  .Examples{
    position: relative;
    z-index: 100;
  }
  .Gal{
    width: 15%;
    height: 15%;
    margin-left: 3%;
    filter: brightness(0) invert(1);
  }
  .flex{
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
  }
  .Background2{
    background: repeating-linear-gradient(45deg, #1565C0, #1565C0 10px, #465298 10px, #465298 20px);
    background-color: #1565C0;
    align-items: center;
  }
  .Textbox{
    border-radius: 30px;
    border-color: aliceblue;
    border-width: 3px;
    border-style: dashed;
    text-align: center;
    margin: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1565C0;
  }
  .container{
    width: 87%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .center{
    align-items: center;
    text-align: center;
  }
  .MuiMenuItem-root{
    color: #ffffff !important;
    /* border-color: #ffffff !important; */
  }
  .MuiTypography-root{
    font-size: 20px !important;
    font-family: "Montserrat Medium", serif !important;
  }
  
  .MuiPaper-root{background-color: #1565C0 !important;}
  .MuiBox-root{
    background-color: #1565C0 !important;
  }
  .Knopka{
    border-color:  #1565C0 !important;
    text-align: center;
    border-width: 0px !important;
    margin: 5%;
  }
  
  .Milo{
    -webkit-box-shadow: 4px 4px 97px 25px rgba(255, 255, 255, 1) inset;
-moz-box-shadow: 4px 4px 97px 25px rgba(255, 255, 255, 1) inset;
box-shadow: 4px 4px 97px 25px rgba(255, 255, 255, 1) inset;
  }
  .Phone{
    width: 69%;
    border-color: #1565C0;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-radius: 0px;
    border-width: 0px;
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.7);
  }
  .open-btn{
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.7) !important;
  }
  .logo{
    width: 15%;
    height: auto;
    margin-top: 1%;
  }

  .Dark{
    background-color: rgba(0, 0, 0, 0.3);
    padding-bottom: 1.55%;
  }
  .containerr{
    width: 80%;
    align-items: center;
  }
  .App {
    text-align: center;
  } 
  a{
    text-decoration: none !important;
  }
  html{
    scroll-behavior: smooth;
  }
  .Container{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .Window1{
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-color: #ffffff;
    border-width: 4px;
    border-radius: 0px;
    padding: 15px;
    width: 70%;
    border-style:dashed;
    margin: auto;

    margin-top: 15%;
    margin-bottom: 10%;
    text-align: center;

    

  }
  .Window2{
    /* background-color: rgba(0, 0, 0, 0.3); */
    background-color: #1565C0;
    border-color: #1565C0;
    border-width: 8px;
    border-radius: 60px;
    padding: 5px;
    width: 80%;
    border-style: solid;
    margin-bottom: 6%;
  }
  .Purum{
    margin: auto;
    width: 75%;
    display: flex;
  }
  .Up{
    width: 87%;
    height: 18%;
    display: inline-flex;
    padding-left: 13%;
    padding-bottom: 60px;
    text-align: center;
      vertical-align: middle;
  }
  .Icons{
    margin-top: 7px;
  }
  .BG{
    background-image: url(./img/Fone.png);
    background-size: cover;
    border-color: #1565C0;
    border-width: 0px;
    border-style: solid;
  }

  .BigPhoto{
    background-image: url(./img/Fone1.png);
    background-size: cover;
    border-color: #1565C0;
    border-width: 0px;
    border-style: solid;
    margin-bottom: 3%;
  }
  .PriceLists{
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .Setka{
    width: 87%;
  }
  text{
    font-family: "Montserrat Medium", serif;
  }
  h8{
    color: aliceblue !important;
    font-size: 26px;
    font-family: "Montserrat Medium", serif;
  }
  .MuiTypography-root  {
    color: aliceblue !important;
    font-size: larger;
  }
  h6{
    color: aliceblue !important;
    font-size: larger;
    font-family: "Montserrat Medium", serif;
  }
  h9{
    color: aliceblue !important;
    font-size: x-large;
    font-family: "Montserrat Medium", serif;

  }
  h10{
    color: aliceblue !important;
    font-size: large;
    font-family: "Montserrat Medium", serif;
  }
  h1{
    color: aliceblue !important;
    font-size: 40px;
    font-family: "Montserrat Medium", serif;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.8);
  }
  h22{
    color: aliceblue !important;
    font-size: 30px;
    font-family: "Montserrat BoldItalic", serif;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.8);
  } 
  h23{
    color: aliceblue !important;
    font-size: 16px;
    font-family: "Montserrat Medium", serif;
  }
  h2{
    color: aliceblue !important;
    font-size: 30px;
    font-family: "Montserrat Medium", serif;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    
  }
  h3{
    color: aliceblue !important;
    font-size:30px;
    font-family: "Montserrat Medium", serif;
    margin: 3%;
  }
  h4{
    color: rgb(6, 123, 226) ;
    font-size: 40px;
    font-family: "Montserrat Medium", serif;
  }
  .formControl{
    width: 100%;
    border-radius: 5px;
    background-color: aliceblue ;
    padding: 1px;
    margin-left: 0% !important;
  }
  .Carousel{
    align-self: flex-end;
    width: 98%;
    height: auto;
    margin-left: auto;
    margin-right: auto ;
    display: block;
    white-space: pre-wrap;
    border:"5px solid #E8044E ";
    background-size: contain;

}
.MuiButton-h6{
  font-size: 18px !important;
  font-family: "Montserrat Medium", serif !important;
}
@media screen and (max-width: 1000px){
  .map{
    margin: 1%;
    /* width: 400px;
    height: 350px; */
  }
  .container{
    width: 90%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .Phone{
    width: 89%;
    border-color: #1565C0;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-radius: 0px;
    border-width: 0px;
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.7);
  }
  .Dark{
    background-color: rgba(0, 0, 0, 0.3);
    padding-bottom: 30%;
  }
  .BG{
    background-image: url(./img/Fone.png);
    background-size: cover;
    border-color: #1565C0;
    border-width: 0px;
    border-style: solid;
  }
  .App {
    text-align: center;
  }
  .Carousel{
    align-self: flex-end;
    width: 98%;
    height: auto;
    margin-left: auto;
    margin-right: auto ;
    display: block;
    white-space: pre-wrap;
    border:"5px solid #E8044E ";
    background-size: contain;

}

  .Window1{
    border-color: #ffffff;
    border-width: 4px;
    border-radius: 0px;
    padding: 5px;
    width: 70%;
    border-style:dashed;
    margin: auto;
    margin-bottom: 15%;
    text-align: center;
    margin-top: 15%;
  }
  .Purum{
    margin: auto;
    width: 95%;
  }
  .Up{
    width: 98%;
    height: 18%;
    display: inline-flex;
    padding-left: 1%;
    padding-bottom: 60px;
    text-align: center;
      vertical-align: middle;
  }
  .Icons{
    margin-top: 7px;
  }

  .BigPhoto{
    background-image: url(./img/Fone.png);
    background-size: cover;
    padding-top: 1%;
    padding-bottom: 24%;
    border-color: #1565C0;
    border-width: 0px;
    border-style: solid;
  }

  .Setka{
    width: 100%;
  }
  .MuiTypography-root {
    color: aliceblue !important;
    font-size: larger;
  }


  h2{
    color: aliceblue !important;
    font-size: 18px;
    font-family: "Montserrat Medium", serif;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  }
  h3{
    color: aliceblue !important;
    font-size: larger;
  }
  h8{
    color: aliceblue !important;
    font-size: 17px;
    font-weight: bold;
  }
  /* h9{
    color: aliceblue !important;
    font-size: 13px !important;
  } */
  h10{
    color: aliceblue !important;
    font-size: large;
  }
  h4{
    color: rgb(6, 123, 226) ;
    font-size: 40px;
  }
  h1{
    font-size: 20px;
  }
  .MuiCardContent-root{
    padding: 0% !important;
    margin: 0%  !important;
  }
  .css-46bh2p-MuiCardContent-root{
    padding: 0% !important;
    margin: 0%  !important;
  }

  img{
    max-height: 400px;
    width: auto;

  }
  .MuiCardActions-root {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }

}
